
.repairs-select-device {
    background-color: #f2f2f2;
    height:90vh;  
}

.next-button a {
    text-decoration: none;
    color: black;
    margin-top: 2rem;
}

.next-button p {
    text-align: center;
    font-size: 18px;
}


.repairs-page-content form, button{
    margin-left: 5rem;
}

.device-select-card {
    width:50%;
    margin:0 auto;
    background-color: black;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.device-select-card form {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width:100%;
    align-items: center;
    margin-left: 0px;
}

.device-select {
    width:30%;
    margin:0 auto;
}

.device-select select {
    display: block;
	font-size: 14px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 20px;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
    font-family: 'Poppins', sans-serif;
}

.device-select-card form select {
    display: block;
	font-size: 14px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 72%;
	box-sizing: border-box;
	margin-bottom: 20px;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
    font-family: 'Poppins', sans-serif;
}
.device-type {
    border-right: 1px solid white;
}

caption {
    color: white;
    text-align: center;
}


.repairs-select-issue {
    background-color: red;
    height:90vh;
    margin-top: -1.5rem;
}

.issue-select-card {
    width:22%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.issue-select-card h3 { 
    text-align: center;
}

.issue-select-card select {
    display: block;
	font-size: 14px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 20px;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
    font-family: 'Poppins', sans-serif;
}



.form-display {
    display: none;
}

.form-display-select {
    display: flex;
}

.repairs-header {
    width:60%;
    margin:0 auto;
    margin-bottom: 3rem;
}

.repairs-header h1 {
    text-align: center;
    padding-top: 3rem !important;
}

.repairs-header p {
    text-align: center;
}


.repairs-select-location {
    background-color: #575759;
    height:90vh;
    margin-top: -1.5rem;
}


.location-select-card {
    width:22%;
    margin:0 auto;
    display: block;
}

.location-select-card h3 { 
    text-align: center;
}

.location-select-card select {
    display: block;
	font-size: 14px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 20px;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
    font-family: 'Poppins', sans-serif;
}

.repairs-next-buttons {
    width:40%;
    margin:0 auto;
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -2rem;
}

.repairs-next-buttons a {
    background-color: #575759;
    padding:0.4rem 2rem 0.4rem 2rem;
    opacity: 0.8;
    width:140px;
    text-align: center;
}

.repairs-next-buttons a:hover {
    opacity: 1;
}

.repairs-next-buttons button {
    background-color: black;
    color:white;
    border: none;
    font-family: 'Poppins', sans-serif;
    padding:0.1rem 2rem 0.1rem 2rem;
    height:40px;
    margin-top: 2rem;
    cursor: pointer;
    opacity: 0.8;
    width:200px;
    font-size: 14px;
} 


.repairs-next-buttons button:hover {
    opacity:1;
}

.device-location {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.repair-img-card {
    width:300px;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.repair-img img {
    width:50%;
    height: 100%!important;
    margin: 0.5rem 0.5rem 0.5rem 4.5rem;
}

.repair-caption {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}

.stepper {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    opacity: 0.8;
}

.location-select-width {
    width:100%;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-family: 'Poppins', sans-serif;
}

.MuiStepIcon-root.MuiStepIcon-active { 
    color:black !important;
}

.device-type-img h4 {
    color: white;
}

@media only screen and (max-width: 600px) {
    .repairs-select-device {
        background-color: #f2f2f2;
        height:100vh;  
    }

    .repairs-select-issue {
        background-color: red;
        height:100vh;
        margin-top: -1.5rem;
    }

    .repairs-select-location {
        background-color: #575759;
        height:100vh;
        margin-top: -1.5rem;
    }

    .device-select-card {
        width:90%;
        margin:0 auto;
        background-color: black;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        justify-content: center;
    }

    .device-select-card form {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .device-type {
        border-right: none;
        border-bottom: 1px solid white;
    }

    .device-type img {
        width:20%;
        height: 100% !important;
    }

    .repairs-header h1 {
        text-align: center;
        padding-top: 3rem !important;
        font-size: 25px;
    }
    
    .repairs-header p {
        text-align: center;
        font-size: 14px;
    }

    .issue-select-card {
        width:90%;
        margin:0 auto;
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .location-select-card {
        width:90%;
        margin:0 auto;
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .repairs-next-buttons {
        width:40%;
        margin:0 auto;
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -2rem;
    }

    .repairs-next-buttons button {
        background-color: black;
        color:white;
        border: none;
        font-family: 'Poppins', sans-serif;
        padding:0rem 0.5rem 0rem 0.5rem;
        height:40px;
        margin-top: 1rem;
        cursor: pointer;
        opacity: 0.8;
        width:200px;
        font-size: 14px;
        margin-left: -0px;
    } 

    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }

    .device-select {
        width:90%;
        margin:0 auto;
    }

    .device-type-img {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .device-type-img h4 {
        color: white;
        margin-top: 1.5rem;
        margin-right: 1rem;
    }
}
