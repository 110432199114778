.product-container {
    width:25%;
    border-bottom: 1px solid #707070;
    margin: 20px;
}

.product-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    transition: .5s ease;
    backface-visibility: hidden;
    opacity:1

}
.product-image img {
    width:60%;
}

.product-info {
    position: relative;
}
.product-info p, h4 {
    text-align: center;
    margin-top: -10px;
}

.product-body h4 {
    text-align: center;
}
.product-info p {
    color:#F04847;
}



.product-container a{
    color:black;
    text-decoration: none;
}

.memory-op {
    opacity: 0.6;
}

.product-overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    margin-left: 0rem;
}


.product-info:hover .product-image {
    opacity: 0.3;
  }
  
.product-info:hover .product-overlay {
    opacity: 1;
}

.product-overlay .overlay-button {
    background-color: black;
    width:100%;
    color: white;
    padding:1rem 1rem 1rem 1rem;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    margin-left: 0rem;
}

@media only screen and (max-width: 600px) {
    .product-container {
        width:40%;
        border-bottom: 1px solid #707070;
        margin: 10px;
    }

    .product-image img {
        width:60%;
        height: 100% !important;
    }
}