
.checkout-container p {
    text-align: center;
    color:black;
}

.checkout-items {
    height: 40vh;
    overflow-y: scroll;
}

.checkout-proceed {
    margin-top: 22em;
    width:100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
}

.checkout-proceed button{
    background-color: black;
    width:100%;
    color: white;
    padding:1rem 1rem 1rem 1rem;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    margin-left: 0rem;
}

.checkout-close {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom:1px solid black;
}

.checkout-close p {
    color:red;
    font-size: 14px;
    margin-top: 10px;
    margin-right: 2rem;
    cursor: pointer;
}

.checkout-close h3 {
    margin-top: 10px;
    margin-left: 0.4rem;
}

.list {
    width:400px;
    
}

@media only screen and (max-width: 600px) {
    .MuiDrawer-paper {
        min-height:100vh !important;
    }

    .checkout-proceed {
        margin-top: 15em;
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: center;
    }

    .list {
        width:350px; 
    }

    
    .checkout-proceed button{
        background-color: black;
        width:100%;
        color: white;
        padding:1rem 1rem 1rem 1rem;
        border: none;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
        margin-left: 0rem;
    }
}
