.nav-container {
    background-color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    z-index: 3;
    position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.logo {
    margin-left: 5rem;
    color: white;
    width:80%;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 5rem;
    width:80%;
}

.nav a {
    padding-left: 0px;
    text-decoration: none;
    color: white;
}

.services-link {
    margin-left: 1.7rem;
}
.nav a:hover {
    border-bottom: 1px solid white;
}

.selected {
    color:black;
}

.products-link {
    position: relative;
    display: inline-block;
    width:20%;
}

.services-link {
    width:20%;
}

.blogs-link {
    width:20%;
}

.products-dropdown {
    display: none;
    position: absolute;
    top:3.9rem;
    right:0rem;
    background-color: #000;
    width: 560px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 5;
}

.products-dropdown a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.products-dropdown a:hover {background-color: #F2F2F2; color: black;}

/* Show the dropdown menu on hover */
.products-link:hover .products-dropdown {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* .products-link:hover  {border-bottom: 1px solid #F2F2F2;} */


.products-display-flex {
    display: flex;
    flex-direction:column;
    justify-content: space-evenly;
}

.cart-link {
    width:10%;
}
.cart-link img {
    width:35%;
    padding-left: 40px;
    cursor: pointer;
}

.logo a {
    text-decoration: none;
    color:white;
}

.hamburger {
    color: white;
    font-size: 2.5em !important;
}

@media only screen and (max-width: 600px) {
    .logo {
        margin-left: 1rem;
        
    }
    
    .nav {
        margin-right: 1rem;
  
    }

    .mobile {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .cart-link {
        width:20%;
        padding-right: 4rem;
    }
    .cart-link img {
        width:80%;
        height: 100% !important;
        cursor: pointer;
    }
}