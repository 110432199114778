.hero-container {
    height: auto;
    width: 100%;
}

.hero-container img {
    min-height:100vh;
    width: 100%;
}

.p-carousel-text {
    font-size: 30px;
    margin-top: -80px;
    text-align: center;
}

.h3-carousel-text {
    font-size: 60px;
}

.carousel-text {
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: -50px;
    width: 100%;
    color:white;
}


@media only screen and (max-width: 600px) {
    .hero-container img {
        width: 100%;
        min-height:50vh;
    }
    
    .hero-container {
        min-height: auto;
        width: auto;
    }

    .carousel-text {
        left: 0px;
        position: absolute;
        top: 0px;
    }
    
    .h3-carousel-text {
        color: #fff;
        font-size: 25px;
      }

      .p-carousel-text {
        font-size: 16px;
        margin-bottom: -30px;
        text-align: center;
      }
}