.product-overview-container {
    margin-top: 0rem;
}
.product-overview {
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    display: flex;
    flex-direction: row;
}

.product-overview-image {
    width:60%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.product-overview-image img {
    width:60%;
    height:80%;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.product-overview-detail {
    width:40%;
}

.detail-line {
    opacity: 0.4;
}

.product-overview-buttons button {
    background-color: black;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    padding:1rem 1rem 1rem 1rem;
    cursor: pointer;
    margin-left: 0rem !important;
}

@media only screen and (max-width: 600px) {
    .product-overview {
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-overview-detail {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-overview-detail h1 {
        text-align: center;
        font-size: 20px;
    }

    .product-overview-detail p {
        text-align: center;
        font-size: 12px;
        margin-top: 0rem;
    }

    .product-overview-detail h3 {
        margin-top: 0rem;
    }

    .detail-line {
        opacity: 0.4;
        margin-top: 0rem;
    }

    .product-overview-buttons {
        margin-bottom:2rem;
    }

    .product-overview-buttons button {
        background-color: black;
        border: none;
        color: white;
        font-family: 'Poppins', sans-serif;
        padding:0.8rem 1rem 0.8rem 1rem;
        cursor: pointer;
        font-size: 10px;
    }

    .description {
        text-align: center;
        list-style-type: none;
    }
}