.footer-container {
    margin-top: 4rem;
}

.logo-section {
    width:40%;
    margin-top: 2rem;
    margin-left: 5rem;
}

.discover-section {
    width:30%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.discover-section  a {
    text-decoration: none;
    color:#F2F2F2;
    line-height: 1.7rem;
}

.customer-section a {
    text-decoration: none;
    color:#F2F2F2;
    line-height: 1.7rem;
}

.customer-section {
    width:30%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.footer-top {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 30vh;
    background-color: black;
}
.social-media {
    margin-top:-1rem;
}

.social-media:hover {
    color:#F2F2F2;
}

.social-icon-size {
    font-size: 15px !important;
    color: white;
}

.social-icon {
    padding-left:3rem;
    padding-right: 3rem;
}


.footer-bottom p {
    margin-left: 5rem;
    font-size: 13px;
}

@media only screen and (max-width: 600px) {
    .logo-section {
        width:40%;
        margin-top: 2rem;
        margin-left: 1rem;
    }

    .footer-top {
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: auto;
        background-color: black;
        flex-wrap: wrap;
        padding-bottom:3rem;
    }

    .logo-section h2 {
        text-align: center;
    }

    .social-media {
        margin-top:-1rem;
        display: flex;
        justify-content: center;
    }

    .logo-section {
        width:100%;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    
    .discover-section {
        width:45%;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .customer-section {
        width:45%;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-bottom p {
        margin-left: 0rem;
        text-align: center;
        font-size: 13px;
    }
}