.repairs-form-customer {
    background-color: #e6dfdf;
    width:80%;
    border-radius: 20px;
}


.repairs-form-address {
    background-color: #e6dfdf;
    width:80%;
    border-radius: 20px;
    margin-top: 2rem;
}

.repairs-form-header {
    margin-left: 1rem;
    padding-top: 0.1rem;
}

.form-group input[type=text], input[type=email] {
    border: none;
    width:100%;
    margin-left:1rem;
    font-family: 'Poppins', sans-serif;
    padding:1rem 0rem 1rem 1rem;
}

.form-group {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.form-group-bottom-margin {
    padding-bottom:3rem;
}

.form-single input[type=text] {
    border: none;
    width:93%;
    margin-left:1rem;
    font-family: 'Poppins', sans-serif;
    padding:1rem 0rem 1rem 1rem;
}


.form-single-margin-bottom {
    margin-bottom: 1rem;
}

.form-button {
    margin-top: 2rem;
    margin-left:-5rem !important
}

.form-button button { 
    border:none;
    background-color: black;
    font-family: 'Poppins', sans-serif;
    width:73%;
    padding:1rem 0rem 1rem 0rem;
    color: white;
    font-size: 17px;
    cursor: pointer;
}

.error-message {
    font-size: 12px;
    color:red;
    margin-left: 1rem;
    width:40%;
}

.error-info {
    width:80%;
}

.error-info p {
    color:red;
    text-align: center;
    font-size: 12px;
}

.form-group-width {
    width:44%;
}

.form-group-width:first-child {
    margin-right: 2rem;
}


@media only screen and (max-width: 600px) { 
    .repairs-form-customer {
        background-color: #e6dfdf;
        width:100%;
        border-radius: 20px;
    }    

    .repairs-form-container form {
        margin-left: 0rem;
        margin-right: 0rem;
    }

    .repairs-form-header h1{
        font-size: 25px;
        text-align: center;
    }

    .repairs-form-header {
        margin-left: 0rem;
        padding-top: 0.1rem;
    }

    .form-group {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: left;
    }

    .form-group-width {
        width:87%;
    }

    .form-group input[type=text], input[type=email] {
        border: none;
        width:100%;
        margin-left:1rem;
        font-family: 'Poppins', sans-serif;
        padding:1rem 0rem 1rem 1rem;
    }

    .form-group-width:first-child {
        margin-right: 2rem;
        margin-bottom: 1rem;
    }

    .repairs-form-address {
        background-color: #e6dfdf;
        width:100%;
        border-radius: 20px;
        margin-top: 2rem;
    }
    
    .form-single input[type=text] {
        border: none;
        width:87%;
        margin-left:1rem;
        font-family: 'Poppins', sans-serif;
        padding:1rem 0rem 1rem 1rem;
    }
    
    .form-button button { 
        border:none;
        background-color: black;
        font-family: 'Poppins', sans-serif;
        width:82%;
        padding:1rem 0rem 1rem 0rem;
        color: white;
        font-size: 17px;
        cursor: pointer;
    }

    .error-info {
        width:100%;
    }

 

}