.contact-page-banner {
    background-color: #f2f2f2;
    height: 30vh;
    margin-bottom: 5rem;
    margin-top: -20px;
}

.contact-page-banner h1 {
    padding-top:3rem;
    margin-left: 5rem;
}

.contact-page-content {
    display: flex;
    flex-direction: row;
}

.contact-map {
    width:70%;
}

.contact-map img {
    width:100%;
    height: 100%;
}

.contact-details {
    width:30%;
    background-color: black;
    margin-right: 0rem;
}

.contact-details h1 {
    text-align: left;
    color: white;
    margin-left: 2rem;
}

.contact-details-header {
    background-color: #f2f2f2 !important;
}

.contact-details-sections {
    margin-left: 2rem;
    color: white;
}

.contact-details-sections p span a {
    color:white;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {

    .contact-page-banner {
        background-color: #f2f2f2;
        height: 10vh;
        margin-bottom: 2rem;
        margin-top: -20px;
    }

    .contact-page-banner h1 {
        padding-top:1rem;
        margin-left: 0rem;
        text-align: center;
    }

    .contact-page-content {
        display: flex;
        flex-direction: column;
    }

    .contact-map {
        width:100%;
    }

    .contact-details {
        width:100%;
        margin-left: 0rem;
        margin-right:0rem;
        margin-top: 2rem;
    }


    .contact-details h1 {
        text-align: left;
        color: white;
        margin-left: 0rem;
    }

    .contact-details h1 {
        text-align: center;
        color: white;
    }
    
    .contact-details p, h3 {
        text-align: center;
    }

    .contact-details-sections {
        margin-left: 0rem;
        color: white;
    }
}