.related-items-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid black;
}

@media only screen and (max-width: 600px) {
    .related-items-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        border-top: 1px solid black;
        flex-wrap: wrap;
    }
}