.header h1 {
    text-align: center;
}

.header {
    margin-top: 3rem;
}

.tab-menu {
    display: flex;
    justify-content: center;
}
.tab-menu a {
    text-align: center;
    padding:3rem;
    text-decoration: none;
    color:#707070;
}

.products-content {
    width:80%;
    margin:0 auto;
}

.featured-products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.web-content {
    margin-top: 0rem;
}


.about-us {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: row;
    height:80vh;
    margin-top: 5rem;
}

.about-info {
    margin-top: 4rem;
    margin-left: 5rem;
    margin-right: 5rem;
    width:40%;
}

.about-image {
    margin-top: 3.5rem;
    width:50%;
    height: auto;
    margin-right: 5rem;
}

.about-image img {
    width:100%;
}

@media only screen and (max-width: 600px) {
    .header {
        margin-top: 0rem;
    }

    .header h1 {
        text-align: center;
        font-size: 25px;
    }

    .tab-menu {
        display: flex;
        justify-content: space-evenly;
        font-size: 13px;
    }

    .tab-menu a {
        text-align: center;
        padding: 0rem ;
        text-decoration: none;
        color: #707070;
    }

    .products-content {
        width:100%;
        margin:0 auto;
    }

    .about-us {
        background-color: #F2F2F2;
        display: flex;
        flex-direction: column;
        height:auto;
        margin-top: 5rem;
    }

    .about-info {
        margin-top: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        width:auto;
        text-align: center;
    }
    
    .about-info p {
        text-align: center;
    }
    
    .about-image {
        margin-top: 1rem;
        width:auto;
        height: auto;
        margin-right: 1rem;
        margin-left: 1rem;
    }
}