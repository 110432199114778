.cart-item-container {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1rem;
    border-bottom: 1px solid black;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.cart-item-image {
    width:20%;
}

.cart-item-image img {
    margin-left: 0rem;
    width: 100%;
    height: auto;
}

.cart-item-info {
    width:60%;
    color: black;
    margin-left: 1rem;
    margin-top: 1rem;
}

.cart-item-remove {
    width:20%;
    margin-left: 3rem;
}

.cart-item-remove p {
    text-decoration: underline;
    font-size: 12px;
    margin-right:10rem;
}

.cart-item-info h5 {
    color:black;
    text-align: left;
    line-height: 0px;
    opacity: 0.5;
}

.cart-item-info h4 {
    color:black;
    text-align: left;
    line-height: 15px;
}

.cart-item-remove p {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .cart-item-image img {
        margin-left: 0rem !important;
        width: 100%;
        height: auto;
    }

    .cart-item-image {
        margin-left: 1rem;
    }
}