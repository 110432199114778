.sponsor-container {
    margin-top: 3rem;
}

.sponsor {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 5rem;
    margin-right: 5rem;
}

.sponsor img {
    width:15%;
}

@media only screen and (max-width: 600px) {
    .sponsor {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .sponsor img {
        width:40%;
        height: 100% !important;
    }

    .sponsor-container {
        margin-top: 3rem;
        width:100%;
    }
}